import React from "react";
import {CopyCode} from "./copy-code";
import {testId} from "../utils/data-test-id";
import {SectionTitle} from "./section-title";

import styles from './loader.module.css'

export interface LoaderProps {
    url: string;
    isTailing: boolean;
}

export const Loader: React.FC<LoaderProps> = ({url, isTailing}) => (
    <div data-testid={testId('loader')}>
        <div className={styles.loaderContent}>
            <SectionTitle title="Request" subtitle="Send hook look a request to inspect it."/>
            {isTailing ? <TailingIndicator/> : <p>Maximum requests (10) tailed</p>}
            <p>Curl</p>
            <CopyCode>{`curl ${url}`}</CopyCode>
            <p>Fetch</p>
            <CopyCode>{`fetch('${url}')`}</CopyCode>
            <p>Python requests</p>
            <CopyCode>{`import requests; requests.get('${url}')`}</CopyCode>
        </div>
    </div>
);


export const TailingIndicator = () => {
    return (<div className={`flex ${styles.tailingIndicator}`} >
        <div className="shadow-pop-bottom" style={{ width: '24px', height: '24px' }} />
        <p>Tailing requests</p>
    </div>);
};
