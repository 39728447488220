import {useMemo} from "react";

export const getApiBaseUrl = (protocol: 'ws' | 'http') => {
    let fullProtocol = protocol;

    if (import.meta.env.DEV) {
        return `${fullProtocol}://localhost:8787`
    }

    fullProtocol += 's';
    return `${fullProtocol}://send.hooklook.dev`
}

export const useBaseUrl = () => {
    return useMemo(() => {
        return {ws: getApiBaseUrl('ws'), http: getApiBaseUrl('http')};
    }, []);
}
