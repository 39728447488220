import React, {useCallback, useMemo, useState} from "react";
import { Select } from "./components/select";
import { SectionTitle } from "./components/section-title";
import { LazyResponseBody } from "./async-components";
import {
  defaultHtmlContent,
  defaultJsonContent,
  defaultTextContent,
  defaultXmlContent,
} from "./utils/default-response-body-content";
import {ContentType, ResponseConfig} from "./types";

const contentTypeValues: Record<string, ContentType> = {
  NONE: undefined,
  JSON: "application/json",
  HTML: "text/html",
  XML: "application/xml",
  TEXT: "text/plain",
};

const getDefaultContentForKey = (contentTypeKey: string) => {
  switch (contentTypeKey) {
    case "JSON":
      return defaultJsonContent();
    case "HTML":
      return defaultHtmlContent();
    case "XML":
      return defaultXmlContent();
    case "TEXT":
      return defaultTextContent();
    case "NONE":
  }
  return "";
}

const getDisplayForContentType = (contentType: ContentType) => {
  if (contentType) {
    const foundValue = Object.keys(contentTypeValues).find((key) => {
      return contentTypeValues[key] === contentType;
    });

    if (foundValue) {
      return foundValue;
    }
  }

  return 'NONE';
};

export interface ResponsePanelProps {
  responseConfig: ResponseConfig;
  mergeResponseConfig: (state: Partial<ResponseConfig>) => void;
}

export const ResponsePanel: React.FC<ResponsePanelProps> = ({
    responseConfig,
  mergeResponseConfig,
}) => {
  const contentTypeKey = useMemo(() => {
    return getDisplayForContentType(responseConfig?.contentType)
  }, [responseConfig?.contentType]);

  const defaultContent = useMemo(() => getDefaultContentForKey(contentTypeKey), [contentTypeKey]);

  const setContentTypeWithConfig = useCallback((key: string) => {
    const contentType = contentTypeValues[key];
    mergeResponseConfig(responseConfig.responseValue ? {
      contentType
    } : {
      responseValue: getDefaultContentForKey(key),
      contentType
    });

  }, [responseConfig, mergeResponseConfig, contentTypeValues]);

  return (
    <>
      <SectionTitle
        title="Response"
        subtitle="Define the servers response body."
      />
      <div className="flex">
        Content-type:
        <Select
          values={Object.keys(contentTypeValues)}
          selected={contentTypeKey}
          setSelected={setContentTypeWithConfig}
        />
      </div>
      {contentTypeKey !== "NONE" && (
        <LazyResponseBody
          mergeResponseConfig={mergeResponseConfig}
          defaultContent={responseConfig.responseValue ?? defaultContent}
          contentType={contentTypeValues[contentTypeKey]}
        />
      )}
    </>
  );
};
