import {decode, encode} from "@msgpack/msgpack";

const TIMEOUT_INCREMENT = 1000;
const MAX_TIMEOUT = 1000 * 300;

const devConnectionInfo = (connected: boolean) => {
    if (import.meta.env.DEV) {
        // @ts-ignore
        window.hookLookConnected = connected;
    }
}

const calculateRetryBackoff = (attempt: number) => {
    const backoff =
        attempt ** 2 * TIMEOUT_INCREMENT +
        Math.floor(Math.random() * TIMEOUT_INCREMENT);
    return Math.min(MAX_TIMEOUT, backoff);
};

export class WebsocketClient<Message> {
    private connecting = false;
    private socket: WebSocket | undefined;
    private bufferedMessages: Array<Uint8Array> = [];

    private attempt = 0;

    constructor(
        private readonly baseUrl: string,
        private readonly channelId: string,
        private readonly messageHandler: (data: Message) => void
    ) {
        this.connect();

        if (import.meta.env.DEV) {
            // @ts-ignore
            window.killConnection = () => {
                console.log('Manually killing connection');
                this.socket?.close(1000, "Client initiating disconnect");
            }
        }
    }

    public get connected() {
        return Boolean(this.socket);
    }

    public connect = () => {
        if (this.connecting) {
            return;
        }

        try {
            let socket = new WebSocket(new URL(`${this.baseUrl}/api/ws/${this.channelId}`));
            socket.onopen = this.onConnected;
            socket.onmessage = this.onMessage;
            socket.onclose = this.onClose;
            socket.onerror = this.onError;

            this.socket = socket;
            this.connecting = true;
        } catch (e) {
            this.connecting = false;
            this.retry();
        }
    };

    public disconnect = () => {
        this.socket?.close(1000, "Client initiating disconnect");
        this.retry();
    };

    public send = (data: Message) => {
        const encoded: Uint8Array = encode(data);

        if (!this.socket) {
            this.bufferedMessages.push(encoded);
            return;
        }

        this.sendBufferedData();
        this.socket.send(encoded);
    };

    private retry = () => {
        this.attempt++;
        setTimeout(this.connect, calculateRetryBackoff(this.attempt));
    };

    private onConnected = () => {
        devConnectionInfo(true);

        this.connecting = false;
        this.sendBufferedData();
    };

    private onMessage = async (event: MessageEvent) => {
        const blob = event.data;
        const data = decode(await blob.arrayBuffer()) as Message;

        this.messageHandler(data);
    };

    private sendBufferedData = () => {
        let bufferedMessage = this.bufferedMessages.pop();
        while (bufferedMessage) {
            this.socket?.send(bufferedMessage);
            bufferedMessage = this.bufferedMessages.pop();
        }
    };

    private onClose = (event: CloseEvent) => {
        devConnectionInfo(false);

        delete this.socket;
        if (event.wasClean) {
            console.log(
                ` Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
        } else {
            console.error(
                ` Connection closed un-cleanly, code=${event.code} reason=${event.reason}`
            );
        }

        this.retry();
    };

    private onError = (event: Event) => {
        devConnectionInfo(false);

        delete this.socket;
        console.error(`Socket error: ${event}`);

        this.retry();
    };
}
