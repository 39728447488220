export const getDetails = () => ({
  message: `This request has been inspected using hooklook.dev`,
  location: `${window.location.href}`,
  time: Date.now(),
});

export const defaultJsonContent = () => JSON.stringify(getDetails(), null, 2);

export const defaultXmlContent = () => {
  const { time, location, message } = getDetails();
  return `<?xml version="1.0" encoding="UTF-8" ?>
  <root>
  <message>${message}</message>
  <location>${location}</location>
  <time>${time}</time>
</root>`;
};

export const defaultHtmlContent = () => {
  const { time, location, message } = getDetails();
  return `<!DOCTYPE html>
<html lang="es">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Hooklook - request inspector</title>
  </head>
  <body>
    <h1>${message}</h1>
    <a href="${location}">Created at: ${location}</a>
    <div>${time}</div>
  </body>
</html>
`;
};

export const defaultTextContent = () => {
  const { time, location, message } = getDetails();

  return `${message}
==========================
Created at: ${location}
${time}`;
};
