import React from "react";
import styles from './footer.module.css';

export const Footer: React.FC = () => {
    return <div>
        <div className={styles.phantom}/>
        <div className={styles.footer}>
            <div className={styles.title}>
                <a href={window.location.href}><h3 className={styles.titleHeader}>Hooklook</h3></a>
                <p className={styles.titleSubtext}>request inspector and no-code backend</p>
                <a className={styles.author} href="https://github.com/spelexander" target="_blank"
                   rel="noopener noreferrer"><strong>@spelexander</strong></a>
            </div>
            <p className={styles.body}>Hooklook is a simple tunneling alternative for inspecting requests to assist with
                webhook integration and prototyping.</p>
            <p>Hooklook <strong>will never log or store any request data.</strong></p>
        </div>
    </div>
}
