import React from "react";

import styles from "./section-title.module.css";

export interface SectionTitleProps {
  title: string;
  subtitle: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="flex">
      <h2>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};
