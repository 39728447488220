import React, {useCallback, useState} from "react";
import {Popover} from "react-tiny-popover";
import styles from "./copy-code.module.css";
import {Button} from "./button";

export interface CopyUrlButtonProps {
    value: string;
}

export const CopyUrlButton: React.FC<CopyUrlButtonProps> = ({ value }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onClick = useCallback(() => {
        let aborted = false;

        if (!value) {
            return;
        }

        navigator.clipboard.writeText(value).catch(console.error);
        setIsPopoverOpen(true);

        setTimeout(() => aborted || setIsPopoverOpen(false), 500);

        return () => {
            aborted = true;
        }
    }, [value]);

    return <Popover
        isOpen={isPopoverOpen}
        padding={10}
        positions={['bottom']}
        content={<div className={styles.copied}>Copied!</div>}
    ><div><Button onClick={onClick}>Copy URL</Button></div></Popover>
}
