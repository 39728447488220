import React from 'react';
import styles from "./input.module.css";

export interface InputProps {
    disabled?: boolean;
    value: string;
}

export const Input: React.FC<InputProps> = ({ disabled, value}) => {
  return (
    <div className={`${styles.inputBottom} grow`}>
      <input disabled={disabled} className={`${styles.textInput} grow`} value={value} />
    </div>
  );
};
