import {nanoid} from "nanoid";

/**
 * If the window is already at an existing channel return it.
 * Otherwise, generate a new channel and return that channel id while
 * changing the window location.
 */
export const getRouteChannel = () => {
    const url = new URL(window.location.href);
    let newPath = nanoid();

    if (url.pathname) {
        const parts = url.pathname.split("/");
        if (parts.length === 2) {
            const [, channelId] = parts;

            if (channelId) {
                newPath = channelId;
            }
        }
    }

    url.pathname = `/${newPath}`;
    const nextTitle = `tailing ... ${newPath}`;
    const nextState = {
        additionalInformation: `Now tailing requests to ${newPath}`,
    };
    window.history.replaceState(nextState, nextTitle, url);

    return newPath;
};
